.ekko-lightbox-container {
  position: relative;
}
.ekko-lightbox-container > div.ekko-lightbox-item {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
}
.ekko-lightbox iframe {
  width: 100%;
  height: 100%;
}
.ekko-lightbox-nav-overlay {
  z-index: 100;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
}
.ekko-lightbox-nav-overlay a {
  flex: 1;
  display: flex;
  align-items: center;
  opacity: 0;
  transition: opacity 0.5s;
  color: #fff;
  font-size: 30px;
  z-index: 100;
}
.ekko-lightbox-nav-overlay a > * {
  flex-grow: 1;
}
.ekko-lightbox-nav-overlay a > *:focus {
  outline: none;
}
.ekko-lightbox-nav-overlay a span {
  padding: 0 30px;
}
.ekko-lightbox-nav-overlay a:last-child span {
  text-align: right;
}
.ekko-lightbox-nav-overlay a:hover {
  text-decoration: none;
}
.ekko-lightbox-nav-overlay a:focus {
  outline: none;
}
.ekko-lightbox a:hover {
  opacity: 1;
  text-decoration: none;
}
.ekko-lightbox .modal-dialog {
  display: none;
}
.ekko-lightbox .modal-footer {
  text-align: left;
}
.ekko-lightbox-loader {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  display: flex;
  /* establish flex container */
  flex-direction: column;
  /* make main axis vertical */
  justify-content: center;
  /* center items vertically, in this case */
  align-items: center;
}
.ekko-lightbox-loader > div {
  width: 40px;
  height: 40px;
  position: relative;
  text-align: center;
}
.ekko-lightbox-loader > div > div {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #fff;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  animation: sk-bounce 2s infinite ease-in-out;
}
.ekko-lightbox-loader > div > div:last-child {
  animation-delay: -1s;
}
.modal-dialog .ekko-lightbox-loader > div > div {
  background-color: #333;
}
@-webkit-keyframes sk-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
  }
}
@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}
